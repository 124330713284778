#deckgl-wrapper {
  cursor: default !important;
}
.container {
  position: relative;
  height: 100vh;
}
.deck-tooltip {
  /* background-color: white !important;
  padding: 10px !important;
  border-radius: 8px !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
  font-family: Arial, sans-serif !important;
  color: #333333 !important;
  font-size: 0.85rem !important;
  line-height: 1.5 !important;
  white-space: nowrap !important; */
}
.deck-tooltip-layer6 {
  /* background-color: #fff !important;
  position: absolute !important;
  transform: none !important;
  z-index: 1000; */
}
.custom-tooltip {
  position: absolute;
  background-color: #fff;
  z-index: 10;
  /* pointer-events: none; */
  width: auto;
  height: auto;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  color: #000;
  font-size: 12px;
  line-height: 1.5;
  max-width: 300px;
}
.info_temp {
  /* position: fixed;
  z-index: 1;
  width: 150px;
  height: 150px;
  background-color: white;
  border: solid black 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}
/* .landuse {
  position: fixed;
  z-index: 1;
  bottom: 50px;
  right: 275px;
  color: #808080;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 15px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.landusenoright {
  right: 10px;
}
.g1 {
  grid-column-start: 1;
  grid-column-end: 3;
  color: #808080;
  font-size: 9px;
}
.gitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333333;
  font-size: 10px;
} */
#b1,
#b2,
#b3,
#b4 {
  width: 29px;
  height: 12px;
  margin-right: 3px;
}
#b1 {
  background-color: #e3e069;
}
#b2 {
  background-color: #b0c5da;
}
#b3 {
  background-color: #eeb898;
}
#b4 {
  background-color: #bed38c;
}
.zoom {
  position: fixed;
  z-index: 1;
  bottom: 100px;
  right: 20px;
  color: #808080;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 10px;
}
.zoom span {
  font-size: xx-large;
  font-weight: bolder;
  color: #003762;
}
.lengthSum {
  position: fixed;
  z-index: 1;
  bottom: 40px;
  right: 20px;
  color: #808080;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 10px;
}
.lengthSum span {
  font-size: xx-large;
  font-weight: bolder;
  color: #003762;
}
.lengthReq {
  height: 38px;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
}
.lengthReq:hover {
  background-color: #e5ebef;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure the overlay is on top of other elements */
}
.loading-spinner {
  position: relative;
  display: inline-block;
  width: 280px;
  height: 120px;
}
.loading-spinner::after {
  content: 'Loading...';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  animation: blinking 2s steps(9) infinite;
  color: #fcfcfd;
  font-size: 4rem;
}

@keyframes blinking {
  0%,
  90% {
    opacity: 1;
  }
  5%,
  95% {
    opacity: 0;
  }
}
.slider {
  width: 100%;
}
.pointer-coordinates {
  z-index: 10;
  position: fixed;
  right: 10px;
  bottom: 300px;
}

@media (max-width: 1015px) {
  .landuse {
    display: none;
  }
  .landusenoright {
    display: grid;
    right: 5px;
    bottom: 5px;
  }
}
