.left_column {
  position: fixed;
  background-color: #0062af; /* #003762 */
  z-index: 3;
  height: 100%;
  width: 50px;
  display: flex;
  flex-direction: column;
}
.left_column:hover {
  width: 250px;
}
.left_column.bar0 {
  width: 250px;
}
.left_column a {
  display: flex;
  font-size: 17px;
  line-height: 72px;
  text-decoration: none;
  color: white;
}
.nstrtimg {
  text-decoration: none;
  width: 32px;
  height: 32px;
  margin: 20px 0px 20px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 5.2px;
}
.titleline {
  width: 100%;
  height: 1px;
  background-color: #00457c;
  margin: 0 0 12px 0;
}
.leftbar_column_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 92%; */
  height: calc(100% - 72px);
}
.leftbar_column_top {
}
.leftbar_column_bottom {
  /* display: flex;
  flex-direction: column;
  height: 300px; */
}
.genBT,
.riskBT,
.priorBT,
.propBT {
  color: #fcfcfd;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 15px;
  line-height: 37px;
  margin: 0px 8px 10px 8px;
  border-radius: 7px;
}
.genBT:hover,
.riskBT:hover,
.priorBT:hover,
.propBT:hover {
  background-color: #ffffff;
  color: #0062af;
  justify-content: space-between;
}
.genBT.active,
.riskBT.active,
.priorBT.active,
.propBT.active {
  background-color: #ffffff;
  color: #0062af;
}
.left_column:hover .genBT,
.left_column:hover .riskBT,
.left_column:hover .priorBT,
.left_column:hover .propBT {
  justify-content: space-between;
}
.left_column.bar0 .genBT,
.left_column.bar0 .riskBT,
.left_column.bar0 .priorBT,
.left_column.bar0 .propBT {
  justify-content: space-between;
}
.topicons {
  width: 23px;
  height: 23px;
  /* margin: 0 0 0 0; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.left_column:hover .topicons {
  margin: 0 10px 0 0;
}
.left_column.bar0 .topicons {
  margin: 0 10px 0 0;
}
.lb_icon1 {
  width: 22px;
  height: 22px;
}
.lb_icon2 {
  width: 48px;
  height: 48px;
}
.lb_icon3 {
  width: 30px;
  height: 30px;
}
.lb_icon4 {
  width: 50px;
  height: 50px;
}
.riskBT:hover .lb_icon2,
.priorBT:hover .lb_icon3,
.propBT:hover .lb_icon4 {
  filter: brightness(0) saturate(100%) invert(22%) sepia(99%) saturate(2080%)
    hue-rotate(191deg) brightness(88%) contrast(101%);
}

.riskBT.active .lb_icon2,
.priorBT.active .lb_icon3,
.propBT.active .lb_icon4 {
  filter: brightness(0) saturate(100%) invert(22%) sepia(99%) saturate(2080%)
    hue-rotate(191deg) brightness(88%) contrast(101%);
}

.hvd_title {
  display: none;
  margin: 0 0 0 13px;
  font-weight: bold;
}
.left_column:hover .hvd_title {
  display: block;
}
.left_column:hover .nstrtimg {
  display: none;
}
.left_column.bar0 .hvd_title {
  display: block;
}
.left_column.bar0 .nstrtimg {
  display: none;
}
.hvd {
  display: none;
  margin: 0 0 0 11px;
  font-weight: bold;
}
.left_column:hover .hvd {
  display: block;
}
.left_column.bar0 .hvd {
  display: block;
}

.filter {
  color: #fcfcfd;
  height: 45px;
  display: flex;
  cursor: pointer;
  font-size: 12px;
  align-items: center;
  width: 100%;
}
.left_column.bar0 .filter {
  display: none;
}
.bottom_cont {
  display: flex;
  align-items: center;
  margin: 0 7px 0 7px;
  width: 100%;
  border-radius: 5px;
  height: 36px;
}
.bottom_cont.active {
  background-color: #fff;
  color: #0062af;
}
.bottom_cont:hover {
  background-color: #fff;
  color: #0062af;
  opacity: 50%;
}
.left_column:hover .bottom_cont {
  justify-content: flex-end;
}
.left_column.bar0 .bottom_cont {
  justify-content: flex-end;
}
.bottomicons {
  width: 23px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 6.5px;
}
.filtericon {
  width: 70%;
  height: 70%;
}
.hvd_bottom {
  display: none;
  margin: 0 0 0 17px;
}
.left_column:hover .hvd_bottom {
  display: block;
}
.left_column.bar0 .hvd_bottom {
  display: block;
}
.search {
  color: #fcfcfd;
  height: 45px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  width: 100%;
}
.searchicon {
  width: 70%;
  height: 70%;
}
.login {
  color: #fcfcfd;
  height: 45px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  width: 100%;
}
.login:hover .loginicon {
  filter: brightness(0) saturate(100%) invert(22%) sepia(99%) saturate(2080%)
    hue-rotate(191deg) brightness(88%) contrast(101%);
}
.mypage {
  color: #fcfcfd;
  height: 45px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  width: 100%;
}
.mypageicon {
  width: 100%;
  height: 100%;
}
.guide {
  color: #fcfcfd;
  height: 45px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  width: 100%;
}
.guideicon {
  width: 85%;
  height: 85%;
}
.detail_div {
  position: fixed;
  background-color: #fcfcfd;
  opacity: 1;
  left: 45px;
  top: 0px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 220px;
  border-right: solid transparent 3px;
  border-image: linear-gradient(to right, #b3b3b3, transparent) 1 100%;
  overflow: hidden;
}
.dtl_ttl {
  height: 72px;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin: 0 0 0 20px;
  color: black;
}
.accordion_div {
  width: 100%;
  color: #333333;
  /* font-size: 12pt; */
  font-weight: bold;
  overflow-y: scroll;
}
.info {
  display: block;
  width: 85%;
}
.info > p {
  font-size: 0.8rem;
}
.info_button:hover {
  opacity: 0.5;
}
.info_button_div {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
}
.prep {
  padding: 0.75rem;
  border-bottom: solid 1px #cccccc;
  border-top: solid 1px #cccccc;
}
.fnt {
  font-weight: 900;
  color: #003762;
  font-size: 9px;
}
.roadNo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-bottom: solid #cccccc 1px;
  margin: 10px;
  color: #cccccc;
}
.modal_dwld_btn {
  position: absolute;
  top: 5px;
  left: 75%;
  transform: translateX(-50%);
  z-index: 113;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  height: 30px;
  border: 1px solid black;
  border-radius: 10px;
  font-weight: bold;
  padding: 10px 0 10px 20px;
  gap: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  background-color: #f1f1f1;
  cursor: pointer;
}
.modal_dwld_btn:hover {
  background-color: #cccccc;
}
.modal_dwld_btn:hover .pdficon {
  background-color: #cccccc;
}

.manual_dwld_btn {
  width: 60px;
  height: 30px;
  text-align: center;
  align-content: center;
}

.pdficon {
  width: 85%;
  height: 85%;
}

@media (hover: none) {
  .left_column:hover {
    width: 50px;
  }
  .left_column:hover .hvd_title {
    display: none;
  }
  .left_column:hover .hvd {
    display: none;
  }
  .left_column:hover .hvd_bottom {
    display: none;
  }
  .left_column.bar0 {
    width: 50px;
  }
  .left_column.bar0 .hvd_title {
    display: none;
  }
  .left_column.bar0 .hvd {
    display: none;
  }
  .left_column.bar0 .hvd_bottom {
    display: none;
  }
  .bottom_cont:hover {
    background-color: initial;
    opacity: initial;
  }
  .bottom_cont.active {
    background-color: initial;
  }
}
