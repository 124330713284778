.ctdd {
  overflow: auto;
  height: 190px;
  width: 100px;
  margin: 20px 10px 10px 10px;
  font-size: 11px;
  text-align: center;
}
.ctdd ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
}
/* .option-checkbox {
  display: none;
} */
.ctdd_option_label {
  display: block;
  padding: 3px 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.ctdd_option_label:hover {
  background-color: #e5ebef;
}
.ctdd_option_label.selected {
  color: #0062af;
  font-weight: 900;
}
