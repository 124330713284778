.map_toggle_button {
  position: fixed;
  top: 5px;
  right: 275px;
  z-index: 1;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fff;
  opacity: 0.75;
  color: #181a1d;
  width: 166px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-family: 'NanumSquare', sans-serif;
  font-weight: bold;
}
.map_toggle_button:hover {
  /* opacity: 0.5; */
  color: #0062af;
}
#map_tg_name {
  margin-left: 12px;
}
#map_tg_icon {
  margin-right: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rmv_basemap {
  right: 10px;
}
.basemap_exp {
  position: fixed;
  z-index: 2;
  top: 46px;
  right: 275px;
  width: 166px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  opacity: 0.75;
  color: #181a1d;
}
.basemap_exp ul {
  list-style-type: none;
  padding-inline-start: 18px;
  margin-block-start: 15px;
  margin-block-end: 15px;
  font-size: 13px;
  font-weight: bold;
}
.basemap_exp li {
  padding: 5px 5px;
}
.basemap_exp li:hover {
  color: #0062af;
  cursor: pointer;
}
.rmv_basemap_exp {
  right: 10px;
}

@media (max-width: 1015px) {
  .map_toggle_button {
    top: 51px;
    width: 126px;
  }
  .basemap_exp {
    top: 92px;
    width: 126px;
  }
}
