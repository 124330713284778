.toggle_button_div {
  position: fixed;
  top: 55px;
  right: 275px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.rmv_control {
  right: 10px;
}
.toggle_button {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #808080;
  opacity: 0.7;
  color: white;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toggle_button:hover {
  opacity: 1;
}
.toggle_button_vs {
  background-color: red;
}

@media (max-width: 1015px) {
  .toggle_button_div {
    top: 101px;
  }
}
