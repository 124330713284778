.rsk_chk_item {
  display: flex;
  align-items: center;
  font-size: 11px;
  padding: 3px 0 3px 0;
  position: relative;
}
.rsk_custom_cb_0,
.rsk_custom_cb_1,
.rsk_custom_cb_2,
.rsk_custom_cb_3,
.rsk_custom_cb_4 {
  display: none;
}
.rsk_custom_cb_0 + .rsk_chk_item::before,
.rsk_custom_cb_1 + .rsk_chk_item::before,
.rsk_custom_cb_2 + .rsk_chk_item::before,
.rsk_custom_cb_3 + .rsk_chk_item::before,
.rsk_custom_cb_4 + .rsk_chk_item::before {
  content: '•';
  display: flex;
  justify-content: center;
  width: 8px;
  height: 8px;
  margin: 3px 8px 3px 20px;
  background: white;
  border: 1px solid #ccc;
  color: white;
  line-height: 11px;
  font-size: 16px;
  border-radius: 3px;
}
.rsk_custom_cb_0:checked + .rsk_chk_item::before {
  background-color: #dd0016;
  border: 1px solid #dd0016;
}
.rsk_custom_cb_1:checked + .rsk_chk_item::before {
  background-color: #e98d78;
  border: 1px solid #e98d78;
}
.rsk_custom_cb_2:checked + .rsk_chk_item::before {
  background-color: #f2d492;
  border: 1px solid #f2d492;
}
.rsk_custom_cb_3:checked + .rsk_chk_item::before {
  background-color: #79c2a5;
  border: 1px solid #79c2a5;
}
.rsk_custom_cb_4:checked + .rsk_chk_item::before {
  background-color: #00afb9;
  border: 1px solid #00afb9;
}

.rsk_chk_item:hover {
  background-color: #f1f1f1;
}
.rskC {
  width: 7px;
  height: 7px;
  margin: 0 7px 0 0;
}
.rskCbox0 {
  background-color: #dd0016;
}
.rskCbox1 {
  background-color: #e98d78;
}
.rskCbox2 {
  background-color: #f2d492;
}
.rskCbox3 {
  background-color: #79c2a5;
}
.rskCbox4 {
  background-color: #00afb9;
}
