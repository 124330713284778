.text-balloon {
  display: inline-block;
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 15px 15px 15px 15px;
  font-size: 11px;
  color: #0062af;
  line-height: 1.6;
  position: relative;
  max-width: 300px;
  margin: 20px 10px 20px 20px;
}

/* Add border to the arrow */
.text-balloon::before {
  content: '';
  position: absolute;
  top: -17px;
  right: 30px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0px 17px 17px 0px;
  border-color: transparent transparent #f1f1f1 transparent;
}
