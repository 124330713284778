.rsrch_d1 {
  width: 230px;
  height: auto;
  border-radius: 15px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
}
.rsrch_d1_expanded {
  flex-direction: column;
}
.rsrch_d2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 230px;
  background-color: #ffffff;
  height: 45px;
  border-radius: 15px;
}
.rsrch_d2:hover {
  background-color: #f1f1f1;
}
.rsrch_lbl {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 800;
  margin: 0 0 0 15px;
}
.rsrch_icon {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 0 15px 0 0;
  color: black;
}
.rsrch_expanded {
  background-color: #ffffff;
  font-size: 12px;
  padding: 0 2px;
  padding: 13.5px 10px 13.5px 10px;
  border-radius: 15px;
}

.rsrch_srch {
  position: relative;
  font-size: 13px;
}
.test333 {
  width: 190px;
}
.rsrch_input {
  padding: 11.2px 15px 11.2px 15px;
  border: none;
  font-size: 13px;
  width: 105px;
}

.rsrch_input:focus {
  outline: none;
}
.test333.input-focused {
  border: 1px solid #5f9ac9;
}
.srchbtn {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border-radius: 4px;
  padding: 9px 11px 9px 11px;
  color: #0062af;
  background-color: #f2f7fb;
  display: flex;
  justify-content: center;
  align-items: center;
}
.srchbtn:hover {
  color: #f2f7fb;
  background-color: #0062af;
}
.btn_ic {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.srchbtn_x {
  position: absolute;
  right: 43px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  color: #0062af;
  display: flex;
  justify-content: center;
  align-items: center;
}
.srchbtn_x:hover {
  color: gray;
}
.btn_ic_x {
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rsrch_drpd {
  background-color: white;
  display: flex;
  flex-direction: column;
  border: none;
  max-height: 200px;
  overflow-y: auto;
  margin: 5px 0 5px 0;
}
.rsrch_drpd:empty {
  border: none;
}
.rsrch_drpd_row {
  cursor: pointer;
  text-align: start;
  padding: 4px 0 4px 12px;
  font-size: 13px;
}
.rsrch_selected {
  background-color: #e5ebef;
}
.rsrch_nfidlst {
  margin: 10px 0 10px 0;
}
.rsrch_nfid_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 3px 5px 3px;
  cursor: pointer;
}
.rsrch_nfid_row:hover {
  background-color: #e5ebef;
}
.rsrch_nfid1 {
  font-size: 11px;
  margin: 2.5px 0 0 0;
  display: flex;
  width: 15px;
  justify-content: center;
}
.rsrch_nfid2 {
  font-size: 11px;
  margin: 2.5px 0 0 0;
  width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rsrch_nfid3 {
  font-size: 11px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0062af;
}
