.bar3_d1 {
  position: relative;
  z-index: 1;
  margin: 0 0 0 0;
  background-color: #ffffff;
  border-radius: 15px;
  width: 100%;
  font-size: 10px;
}
.bar3_d2 {
  height: 80px;
}
.bar3_ttp {
  /* position: relative;
  z-index: 10000; */
  background-color: #ffffff;
  padding: 6px 15px 3px 15px;
  border-radius: 10px;
  border: 0.5px solid rgba(128, 128, 128, 0.3);
  opacity: 90%;
  font-size: 9px;
}
.bar3_ttp_lbl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  width: 100px;
}
.bar3_cirtxt {
  display: flex;
  align-items: center;
}
.bar3_cirs {
  border-radius: 100%;
  width: 5px;
  height: 5px;
  margin: 0 5px 0 0;
}
.bar3_legend {
  font-size: 8px;
  color: black;
  margin: 0 0 0 60px;
  display: flex;
  justify-content: space-between;
}
.bar3_legend2 {
  /* font-size: 9px; */
  margin: 0 0 0 0;
}

/* .bar3div { */
/* position: relative; Required for z-index to work */
/* z-index: 1; */
/* margin: 0 0 0 0; */
/* transform: translateY(-20px); */
/* margin: -30px 0 10px 0px; Moves the chart container up */
/* display: flex; */
/* justify-content: center; */
/* align-items: center; */
/* } */
