.rpfr_d1 {
  margin: 0 0 10px 0;
  background-color: #ffffff;
  border-radius: 15px;
  width: 230px;
}
.rpfr_d2_x {
  width: 230px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 15px;
  color: #b9b9b9;
}
.rpfr_d2 {
  width: 230px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 15px;
}
.rpfr_d2:hover {
  background-color: #e5ebef;
}
.rpfr_lbl {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 800;
  margin: 0 0 0 15px;
}
.rpfr_icon {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 0 15px 0 0;
  color: #6687a0;
}
.rpfr_exp {
  background-color: #ffffff;
  font-size: 12px;
  padding: 13.5px 10px 13.5px 10px;
  border-radius: 15px;
}
.rpfr_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rpfr_acc_d1 {
  width: 200px;
}
.rpfr_line {
  height: 1px;
  background-color: gray;
  opacity: 30%;
  display: flex;
  align-items: center;
}
.rpfr_acc_d2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  padding: 0px 10px;
}
.rpfr_acc_d2:hover {
  background-color: #e5ebef;
}

.rpfr_acc_lbl {
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: bold;
  margin: 3px 0 0 0px;
}
.rpfr_acc_icon {
  font-size: 13px;
  width: 13px;
  height: 13px;
  color: #6687a0;
}

/* .rpfr_acc1_exp {
  border: 1px solid red;
} */

.rpfr_itms1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
}
.rpfr_itm1 {
  width: 185px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
}
.rpfr_itm1_ttl {
  margin: 1px 0 0 0;
  font-weight: bold;
}
.rpfr_itm1_val {
  margin: 4px 0 0 0;
}
