.prpbff_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0 0;
}
.prpbff_lbl {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  width: 100%;
  height: 40px;
  font-size: 12px;
  cursor: pointer;
}

.bff_qsmark {
  position: relative;
  margin-left: 10px;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  background-color: #c2c2c2;
  border: 1px solid #0000003d;
  color: black;
  font-size: 7px;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.bff_qsmark:hover .bff_qs_tltp {
  visibility: visible;
}

.bff_qs_tltp {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 15px;
  left: -10px;
  width: 120px;
  height: 30px;
  font-size: 10px;
  font-weight: bold;
  align-content: center;
  color: black;
  padding: 5px;
  background-color: #fcfcfd;
  border: 1px solid #cccccc;
  border-radius: 8px;
  box-shadow: 1px 1px 4px #00000024;
}

.bffexp_icon {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -10px 0 0;
}

.bfflg_content {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bfflg_clickbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.bfflg_clickbox:hover {
  background-color: #f1f1f1;
}

.bff_custom_cb {
  display: none;
}

.bff_chk_item {
  display: flex;
  align-items: center;
  padding: 8px 5px 8px 0;
  margin: 5px 0 5px 0;
  position: relative;
  cursor: pointer;
}
.bff_chk_item:hover {
  background-color: #f1f1f1;
}

.bff_custom_cb + .bff_chk_item::before {
  content: '•';
  display: flex;
  justify-content: center;
  width: 8px;
  height: 8px;
  margin: 0 8px 0 10px;
  background: white;
  border: 1px solid #ccc;
  color: white;
  line-height: 10px;
  font-size: 16px;
  border-radius: 3px;
}
.bff_custom_cb:checked + .bff_chk_item::before {
  background-color: #0062af;
  border: 1px solid #0062af;
}

.bff_chk_word {
  margin-top: 1px;
  padding-bottom: 1px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bfflg_dashline {
  flex: auto;
  height: 1px;
  border-bottom: 1px dashed #9b9b9b;
  margin: 0 10px 0 5px;
}

.bfflg_color {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  border: 1px solid #0000003d;
  border-radius: 20px;
}
.bfflg_color_crosswalk {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #f5d15c;
  border-radius: 2px;
  margin-bottom: 5px;
  margin-right: -4px;
}

.bfflg_color_toggle {
  position: relative;
  width: 12px;
  height: 12px;
  border: 1px solid #0000003d;
  border-radius: 20px;
  background-color: #c2c2c2;
  font-size: 10px;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.bfflg_color_toggle:hover .bfflg_mfac_tooltip {
  visibility: visible;
}

.bfflg_mfac_tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  width: 120px;
  height: 140px;
  font-size: 10px;
  font-weight: bold;
  background-color: #fcfcfd;
  border: 1px solid #cccccc;
  border-radius: 8px;
  box-shadow: 1px 1px 4px #00000024;
}

.mfac_ttp_content {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 98%;
  padding: 1px 5px 0 10px;
}

.bfflg_color_inner {
  width: 7px;
  height: 7px;
  border: 1px solid #0000003d;
  border-radius: 15px;
  margin-right: 3px;
}
