.prp_sbttl {
  height: 50px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 20px;
  font-weight: 800;
}
.prp_sbtt1_rgt {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 10px;
  margin: 0 10px 0 0;
  cursor: pointer;
}
.prp_sbtt1_rgt:hover {
  background-color: #e5ebef;
}
.prp_sbtt1_rgt_lbl {
  margin: 1.4px 5px 0 0;
  font-size: 9px;
}
.prp_btn_all {
  margin: 1px 0 0 0;
  height: 10px;
  width: 10px;
  background-color: white;
  border: 1px solid #ccc;
  /* line-height: 20px; */
  font-size: 20px;
  border-radius: 3px;
}
.prp_all {
  background-color: #0062af;
  border: #0062af solid 1px;
  color: white;
  display: flex;
  justify-content: center;
  line-height: 15px;
}
.prp_d1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 30px;
  cursor: pointer;
  font-size: 12px;
  color: #0062af;
  font-weight: bolder;
}
.prp_d1:hover {
  background-color: #e5ebef;
}
.prplbl {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}
.prpline {
  height: 1px;
  background-color: gray;
  opacity: 30%;
  display: flex;
  align-items: center;
  flex-grow: 0.8;
}
.prp_icon {
  font-size: 10pt;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
  color: #6687a0;
}
.prp_expanded {
  color: #333333;
  width: auto;
  font-size: 12px;
  padding: 0 20px;
}
