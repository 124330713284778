.rightbar {
  position: fixed;
  background-color: #f5f5f5; /*00000029 f5f5f5*/
  right: 0px;
  z-index: 2;
  height: calc(100% - 55px);
  width: 265px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rb_accordion_div {
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 0 0;
}
.separation {
  width: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0 15px 0;
}
.rb_line {
  height: 1px;
  background-color: #b9b9b9;
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.sep_txt {
  margin: 0 15px 0 15px;
  font-size: 10px;
  color: #b9b9b9;
}
.lngthS {
  width: 230px;
  height: 45px;
  background-color: #ffffff;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px 0;
}
.lngthS_txt {
  font-size: 13px;
  color: #b9b9b9;
  margin: 0 0 0 15px;
  font-weight: 800;
}
.km {
  font-size: 13px;
  color: #b9b9b9;
  margin: 0 15px 0 0;
}
.lngthReq {
  cursor: pointer;
}
.lngthReq:hover {
  background-color: #e5ebef;
}
.id_finder {
  z-index: 1;
}

.lngthPfr_txt {
  font-size: 13px;
  color: black;
  margin: 0 0 0 15px;
  font-weight: 800;
}
