.gen1_d1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 15px;
  margin: 0 0 0 5px;
  cursor: pointer;
  color: #0062af;
  font-weight: bolder;
}
.gen1_d1:hover {
  background-color: #f1f1f1;
}
.gen1_d1.active {
  background-color: #f1f1f1;
}
.genlbl {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: #b9b9b9;
  font-size: 13px;
  font-weight: 800;
}
.genlbl.active {
  color: black;
}
.gen1_icon {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px 0 0;
  color: #c2c2c2;
}
.gen1_icon.active {
  color: black;
}
/* .gen1_line {
  height: 1px;
  background-color: gray;
  opacity: 30%;
  display: flex;
  align-items: center;
  width: 87.5%;
  margin: 0 0 0 20px;
} */
.gen1_expanded {
  color: #333333;
  width: auto;
  font-size: 12px;
  padding: 0 20px;
}
.유형별위험도구성비_gen1_exp {
  margin: 0 0 20px 0;
}
.gen1_csvdl {
  position: fixed;
  bottom: 12px;
  /* bottom: 1.5%; */
  margin: 0 0 0 20px;
  color: black;
  background-color: #f1f1f1;
  font-size: 13px;
  padding: 7px 35px;
}
.gen1_csvdl:hover {
  cursor: pointer;
  color: #0062af;
}
