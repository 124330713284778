.tbl1_cont {
  width: calc(100% - 537px);
  margin-left: 270px;
  margin-right: 265px;
  padding: 10px;
  box-sizing: border-box;
  bottom: 0px;
  position: fixed;
  z-index: 4;
  background-color: white;
  border-radius: 15px;
}
.tbl1_cont_x {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tbl1_head {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.tbl1_head1 {
  font-size: 14px;
}
.tbl1_head2 {
  gap: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 800;
  text-decoration: underline;
}
.tbl1_save {
  margin: 0 3px 0 0;
}
.tbl1_edit:hover,
.tbl1_save:hover {
  cursor: pointer;
  color: #0062af;
}
.tbl1_head3 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.tbl1_head3_1 {
  font-size: 11px;
  font-weight: 600;
  margin: 7px 0 5px 0;
}
.tbl1 {
  width: 100%;
  border-collapse: collapse;
  font-size: 10px;
  border-bottom: 3px solid #ddd;
}
.tbl1_th_top {
  border-top: 3px solid #ddd;
  border-bottom: 3px solid #ddd;
  height: 25px;
  font-weight: 800;
}
.tbl1_th_ldc {
  border: 1px solid #ddd;
  border-left: 0px solid #ddd;
  width: 5rem;
  background-color: #f1f1f1;
  font-weight: 800;
}
.tbl1_th_prp {
  border: 1px solid #ddd;
  width: 8rem;
  font-weight: 600;
}
.tbl1_td {
  width: 5rem;
  border: 1px solid #ddd;
  text-align: center;
  height: 25px;
}
.tbl1_tdpd {
  width: 2.2rem;
  background-color: #f1f1f1;
  border-bottom: 1px solid #ddd;
  text-align: center;
}
.table_cont_x {
  text-align: center;
  /* border: 1px solid black; */
  animation: flicker 1s infinite;
}
@keyframes flicker {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
