.gen1_chk_item {
  display: flex;
  align-items: center;
  padding: 8px 0 8px 0;
  margin: 5px 0 5px 0;
  position: relative;
  /* line-height: 30px; */
}
.gen1_custom_cb {
  display: none;
}
.gen1_custom_cb + .gen1_chk_item::before {
  content: '•';
  display: flex;
  justify-content: center;
  width: 8px;
  height: 8px;
  margin: 0 8px 0 10px;
  background: white;
  border: 1px solid #ccc;
  color: white;
  line-height: 11px;
  font-size: 16px;
  border-radius: 3px;
}
.gen1_custom_cb:checked + .gen1_chk_item::before {
  background-color: #0062af;
  border: 1px solid #0062af;
}
.gen1_chk_item:hover {
  background-color: #f1f1f1;
}
/* .gen1C {
} */
.gen1_chk_word {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* line-height: 15px; */
  margin: 2px 0 0 0;
  font-size: 11px;
}
/* .gen1Cbox0 {
  background-color: #dd0016;
}
.gen1Cbox1 {
  background-color: #e98d78;
}
.gen1Cbox2 {
  background-color: #f2d492;
}
.gen1Cbox3 {
  background-color: #79c2a5;
}
.gen1Cbox4 {
  background-color: #00afb9;
} */
