.rprp_d1 {
  margin: 0 0 10px 0;
  background-color: #ffffff;
  border-radius: 15px;
  width: 230px;
}
.rprp_d2_x {
  width: 230px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 15px;
  color: #b9b9b9;
}
.rprp_d2 {
  width: 230px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 15px;
}
.rprp_d2:hover {
  background-color: #e5ebef;
}
.rprp_lbl {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 800;
  margin: 0 0 0 15px;
}
.rprp_icon {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 0 15px 0 0;
  color: #6687a0;
}
.rprp_exp {
  background-color: #ffffff;
  font-size: 12px;
  padding: 13.5px 10px 13.5px 10px;
  border-radius: 15px;
}
.rprp_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rprp_acc_d1 {
  width: 194px;
}
.rprp_line {
  height: 1px;
  background-color: gray;
  opacity: 30%;
  display: flex;
  align-items: center;
}
.rprp_acc_d2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  padding: 0px 10px;
}
.rprp_acc_d2:hover {
  background-color: #e5ebef;
}

.rprp_acc_lbl {
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  margin: 3px 0 0 0px;
}
.rprp_acc_icon {
  font-size: 13px;
  width: 13px;
  height: 13px;
  color: #6687a0;
}

.rprp_itms1,
.rprp_itms2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
}
.rprp_itm1 {
  width: 175px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
}
.rprp_itm1_ttl {
  margin: 1px 0 0 0;
  color: #6687a0;
}
.rprp_itm1_val {
  margin: 4px 0 0 0;
}
.rprp_itms2_sqr {
  width: 175px;
}
.rprp_itms2_ttl {
  color: #6687a0;
}
.rprp_itms2_cnt {
  display: flex;
  flex-direction: column;
}
.rprp_itm2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  margin: 0 0 0 30px;
}
.rprp_itm2_ttl {
  margin: 1px 0 0 0;
  color: #707070;
}
.rprp_itm2_val {
  margin: 4px 0 0 0;
}
