.prp_custom_cb {
  display: none;
}
.prp_custom_cb + .prp_chk_item::before {
  content: '•';
  display: flex;
  justify-content: center;
  width: 8px;
  height: 8px;
  margin: 0 8px 0 10px;
  background: white;
  border: 1px solid #ccc;
  color: white;
  line-height: 11px;
  font-size: 16px;
  border-radius: 3px;
}
.prp_custom_cb:checked + .prp_chk_item::before {
  background-color: #0062af;
  border: 1px solid #0062af;
}
.prp_chk_item {
  display: flex;
  align-items: center;
  height: auto;
  line-height: 12px;
  padding: 6px 0 6px 0;
}
.prp_chk_item:hover {
  background-color: #e5ebef;
}
