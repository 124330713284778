.bottomright {
  width: 265px;
  height: 55px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  font-size: 10px;
  letter-spacing: 0px;
  color: #808080;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  box-shadow: 0px 3px 6px #00000029;
}
.zoomlevel {
  margin: 7px 10px 0 0;
  color: #333333;
  font-weight: 800;
}
.lnglat {
  margin: 0 10px 0 0;
}
.tag {
  margin: 0 10px 7px 0;
}
