.pfr2a_top5req {
  background-color: #e6ebef;
  width: 84%;
  border-radius: 3px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  margin: 10px 0px;
  cursor: pointer;
}

.pfr2a_d1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 3px 10px 3px;
  cursor: pointer;
  font-size: 12px;
  color: #0062af;
  font-weight: bolder;
}
.pfr2a_d1:hover {
  background-color: #e5ebef;
}

.pfr2a_label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}

.pfr2a_line {
  height: 1px;
  background-color: gray;
  opacity: 30%;
  display: flex;
  align-items: center;
  flex-grow: 0.8;
}

.pfr2a_icon {
  font-size: 10pt;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
  color: #6687a0;
}

.pfr2a_expanded {
  color: #333333;
  width: auto;
  font-size: 12px;
  padding: 0 2px;
  overflow-y: scroll;
  max-height: 200px;
}

.pfr2a_dwnld {
  background-color: #e6ebef;
  width: 78%;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  margin: 15px 0px 10px 5px;
  cursor: pointer;
}
.pfr2a_dwnld:hover {
  background-color: antiquewhite;
}

.pfr2a_input {
  background-color: #e6ebef;
  width: 78%;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  margin: 12px 0px 10px 5px;
  cursor: pointer;
}
.pfr2a_input:hover {
  background-color: antiquewhite;
}

.pfr2a_csvdwn {
  cursor: pointer;
  padding: 3px 3px 3px 3px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.pfr2a_csvdwn:hover {
  background-color: #e5ebef;
}

.csv_nbox {
  font-size: 14px;
  height: 14px;
  display: flex;
  align-items: center;
}

.csv_lbl {
  font-size: 11px;
  width: 120px;
  margin-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pfrc_number_box {
  background-color: #b9b9b9;
  color: white;
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  width: 12px;
  height: 12px;
  border-radius: 4px;
  line-height: 12px;
}

.pfr2a_noreg_text {
  margin: 10px 0;
  text-align: center;
  font-weight: 800;
}
