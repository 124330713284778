/* body {
    
  background: #f0f2f5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  
} */
.login-body {
  background: #f0f2f5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}
.login-container {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.login-container h2 {
  margin-bottom: 1.5rem;
  color: #333;
}

.login-form .form-group {
  margin-bottom: 1rem;
  text-align: left;
}

.login-form label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
}

.login-form input {
  width: 93.5%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.login-form input:focus {
  border-color: #007bff;
  outline: none;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background: #007bff;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
}

.login-button:hover {
  background: #0056b3;
}

.login-goback {
  margin: 15px 0 0 0;
  width: 94%;
  padding: 0.75rem;
  background: #999999;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
}

.login-goback:hover {
  background: #cccccc;
}
