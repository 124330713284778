.yr_d1 {
  width: 230px;
  height: auto;
  border-radius: 15px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
}
.yr_d1_expanded {
  flex-direction: column;
}
.yr_d2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 230px;
  background-color: #ffffff;
  height: 45px;
  border-radius: 15px;
}
.yr_d2:hover {
  background-color: #e5ebef;
}
.yr_lbl {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 800;
  margin: 0 0 0 15px;
}
.yr_icon {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 0 15px 0 0;
  color: #6687a0;
}
.yr_expanded {
  background-color: #ffffff;
  font-size: 12px;
  padding: 13.5px 10px 13.5px 10px;
  margin: 0 0 0 10px;
  /* border: 2px solid #f1f1f1;
  border-radius: 3px; */
}

.yr_srch {
  position: relative;
  font-size: 13px;
  cursor: pointer;
  border: 2px solid #f1f1f1;
  display: flex;
  justify-content: flex-end;
  border-radius: 3px;
}
.yr_srch.active {
  border: 2px solid #0062af;
}
.yr_srch:hover {
  /* background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  border: 1.5px solid var(--unnamed-color-0062af);
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 2px #54a8eb80; */
  border: 2px solid #0062af;
  /* border-radius: 3px;
  opacity: 1; */
}
.yr_input_cont {
  width: 150px;
  display: flex;
  align-items: center;
  /* border: 2px solid #f1f1f1; */
}
/* .yr_input_cont:hover {
  
} */
.yr_input {
  margin: 0 0 0 13px;
  padding: 0 0 0 0;
  border: none;
  font-size: 13px;
  width: 105px;
  /* min-height: 13px; */
  /* display: flex;
  align-items: center; */
  line-height: 15px;
}

.yr_input:focus {
  outline: none;
}
/* .yr_input_cont.input-focused {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  border: 1.5px solid var(--unnamed-color-0062af);
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 2px #54a8eb80;
  border: 2px solid #0062af;
  border-radius: 3px;
  opacity: 1;
} */
.yr_srchbtn {
  /* position: absolute; */
  /* right: 5px; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
  /* cursor: pointer; */
  /* border-radius: 4px; */
  /* margin: 0 0 0 0; */
  padding: 9px 11px 9px 11px;
  color: #0062af;
  /* background-color: #f2f7fb; */
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .yr_srchbtn:hover {
  color: #f2f7fb;
  background-color: #0062af;
} */
.yr_btn_ic {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .yr_srchbtn_x {
  position: absolute;
  right: 43px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  color: #0062af;
  display: flex;
  justify-content: center;
  align-items: center;
}
.yr_srchbtn_x:hover {
  color: gray;
}
.yr_btn_ic_x {
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.yr_drpd {
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: column;
  /* border: none; */
  border: 2px solid #f1f1f1;
  /* max-height: 90px; */
  width: 179px;
  height: 100px;
  overflow-y: auto;
  margin: 0 0 5px 0;
  z-index: 99;
}
.yr_drpd:empty {
  border: none;
}
.yr_drpd_row {
  cursor: pointer;
  text-align: start;
  padding: 4px 0 4px 12px;
  font-size: 13px;
  min-height: 20px;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  align-items: center;
  line-height: 20px;
  /* text-align: center; */
}
.yr_selected {
  background-color: #e5ebef;
}
/* .yr_nfidlst {
  margin: 10px 0 10px 0;
}
.yr_nfid_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 3px 5px 3px;
  cursor: pointer;
}
.yr_nfid_row:hover {
  background-color: #e5ebef;
}
.yr_nfid1 {
  font-size: 11px;
  margin: 2.5px 0 0 0;
  display: flex;
  width: 15px;
  justify-content: center;
}
.yr_nfid2 {
  font-size: 11px;
  margin: 2.5px 0 0 0;
  width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.yr_nfid3 {
  font-size: 11px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0062af;
} */
