.rsk2b_d1 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 3px 10px 3px;
  cursor: pointer;
  font-size: 11.5px;
  color: black;
  /* color: #0062af; */
  font-weight: bolder;
  z-index: 2;
}
.rsk2b_d1:hover {
  background-color: #f1f1f1;
}
/* .rsk2b_d1.active {
  background-color: #f1f1f1;
} */
.rsk2b_label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  color: #b9b9b9;
  margin: 0 0 0 18px;
}
.rsk2b_label.active {
  color: black;
}
.rsk2b_line {
  height: 1px;
  background-color: gray;
  opacity: 30%;
  display: flex;
  align-items: center;
  flex-grow: 0.8;
}
.rsk2b_icon {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px 0 0;
  color: #c2c2c2;
}
.rsk2b_icon.active {
  color: black;
}
.rsk2b_expanded {
  color: #333333;
  width: auto;
  font-size: 12px;
  padding: 0 0;
  margin: 0 0 5px 0;
}

.rsk2b_test {
  cursor: pointer;
}
.rsk2b_test:hover {
  background-color: yellow;
}
.crwdac {
  width: 100%;
}
.rsk2b_qmrk {
  margin: 4px 0 0 0;
  cursor: pointer;
}
.rsk2b_qmrk_div {
  position: absolute;
  bottom: 100%;
  left: -14px;
  width: 180px;
  height: 145px;
  border-radius: 15px;
  border: 0.5px solid #707070;
  background-color: #ffffff;
  font-size: 9.5px;
  color: #333333;
  box-shadow: 0px 3px 6px #0000004d;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 5px 10px 5px 10px;
  margin: 0 0 5px 0;
}
.rsk2b_qmrk_div2 {
  font-size: 9px;
  height: 130px;
}
.rsk2b_qmrk_txt1 {
  font-weight: 800;
}
.rsk2b_qmrk_txt2 {
  font-weight: 400;
}
.rsk2b_qmrk_txt2 span {
  font-weight: 600;
  text-decoration: underline;
}
.rsk2b_qmrk_txt3 {
  font-weight: 600;
}
