.rrsk_d1 {
  margin: 0 0 10px 0;
  background-color: #ffffff;
  border-radius: 15px;
  width: 230px;
}
.rrsk_d2_x {
  width: 230px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 15px;
  color: #b9b9b9;
}
.rrsk_d2 {
  width: 230px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 15px;
}
.rrsk_d2:hover {
  background-color: #e5ebef;
}
.rrsk_lbl {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 800;
  margin: 0 0 0 15px;
}
.rrsk_icon {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 0 15px 0 0;
  color: #6687a0;
}
.rrsk_exp {
  background-color: #ffffff;
  font-size: 12px;
  padding: 13.5px 10px 13.5px 10px;
  border-radius: 15px;
}
.rrsk_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rrsk_acc_d1 {
  width: 194px;
}
.rrsk_line {
  height: 1px;
  background-color: gray;
  opacity: 30%;
  display: flex;
  align-items: center;
}
.rrsk_acc_d2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  padding: 0px 10px;
}
.rrsk_acc_d2:hover {
  background-color: #e5ebef;
}

.rrsk_acc_lbl {
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  margin: 3px 0 0 0px;
}
.rrsk_acc_icon {
  font-size: 13px;
  width: 13px;
  height: 13px;
  color: #6687a0;
}

.rrsk_itms1,
.rrsk_itms2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
}
.rrsk_itm1 {
  width: 175px;
  display: flex;
  justify-content: space-between;
  color: #6687a0;
}

.rrsk_itm2_sqr {
  width: 175px;
}
.rrsk_itms2_cnt {
  display: flex;
  flex-direction: column;
}
.rrsk_itm2 {
  display: flex;
  justify-content: space-between;
  margin: 0 0 0 30px;
}
/**/
.rrsk_scr_wrap {
  height: auto;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 7px 0 7px 0;
}
.rrsk_scr_wrap_x {
  height: 72px;
  background-color: #f2f7fb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rrsk_scr_container {
  display: flex;
  overflow: hidden;
  height: auto;
}
.rrsk_scr_no_lbl {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rrsk_scr_item {
  width: 37.1px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
  line-height: 30px;
}

.rrsk_scr_lbl {
  font-size: 8px;
  color: #000;
  width: 33.6px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-left: 1px solid #e4e4e4; */
  margin: 5px 0 0 0;
}

.rrsk_scrlbl5 {
  /* border-right: 1px solid #e4e4e4; */
}

.ritem1 {
  background-color: #00afb9;
  border-left: 1px solid #00afb9;
  border-right: 1px solid #00afb9;
}
.ritem2 {
  background-color: #79c2a5;
  border-right: 1px solid #79c2a5;
}
.ritem3 {
  background-color: #f2d492;
  border-right: 1px solid #f2d492;
}
.ritem4 {
  background-color: #e98d78;
  border-right: 1px solid #e98d78;
}
.ritem5 {
  background-color: #dd0015;
  border-right: 1px solid #dd0015;
}

.indctr_wrap {
  display: flex;
  justify-content: center;
  padding: 0 0 0 0;
}
.indcatr {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  width: auto;
  height: 23px;
}
.indcatr_inwrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.indcatr_lbl_up {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  margin: 0 0 -4px 0;
}
.indcatr_trngl_up {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #dd0015;
  margin: 0 0 -2px 0;
}
.indcatr_trngl_down {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #dd0015;
  margin: 0px 0 0 0;
}
.indcatr_lbl_down {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  margin: -3px 0 0 0;
}

.rrsk_scr_lbl_wrap {
  display: flex;
  justify-content: space-between;
  margin: 0px 3px 5px 3px;
}
.rrsk_emid {
  font-size: 10px;
  width: 194px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  font-weight: 800;
  margin: 5px 0 0 0;
}
.rrsk_emid_lbl {
  display: flex;
  align-items: center;
  color: #000;
  margin: 0 0 0 10px;
  position: relative;
}
.rrsk_emid_v {
  display: flex;
  align-items: center;
  margin: 2.5px 10px 0 0;
}
.rrsk_qmrk {
  margin: 4px 0 0 0;
  cursor: pointer;
}
.rrsk_qmrk_div {
  position: absolute;
  bottom: 100%;
  left: -32.5px;
  z-index: 10;
  width: 220px;
  height: 160px;
  border-radius: 15px;
  border: 0.5px solid #707070;
  background-color: #ffffff;
  font-size: 10px;
  color: #333333;
  box-shadow: 0px 3px 6px #0000004d;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 5px 10px 5px 10px;
  margin: 0 0 5px 0;
}
.rrsk_qmrk_txt1 {
  font-weight: 800;
}
.rrsk_qmrk_txt2 {
  font-weight: 400;
}
.rrsk_qmrk_txt2 span {
  font-weight: 600;
  text-decoration: underline;
}
.rrsk_qmrk_txt3 {
  font-weight: 600;
}
.rrsk_anlys_mean {
  margin: 17px 7px 7px 7px;
  font-size: 11px;
}
